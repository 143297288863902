















































































































import VueBase from '../../VueBase'
import { Component, Watch } from 'vue-property-decorator'
import { ProjectObj } from '../project/types'
import { formatTimestamp } from '@/utils/utils'

import Distribution from '../project/components/distribution.vue'
import Trend from '../project/components/trend.vue'
import Type from '../project/components/type.vue'
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
])
@Component({
  name: 'ProjectDetail',
  components: {
    Distribution,
    Trend,
    Type,
  },
})
export default class ProjectDetail extends VueBase {
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vm: any) => {
      // 通过 `vm` 访问组件实例
      if (vm.$route.meta.i18n === 'menu.projectDetail') {
        vm.$store.dispatch('setRouteInfo', [])
      }
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (
      to.meta.i18n === 'menu.vulnDetail' ||
      to.meta.i18n === 'menu.scaDetail' ||
      to.meta.i18n === 'menu.scanDetail'
    ) {
      this.$store.dispatch('setRouteInfo', [])
    } else {
      this.$store.dispatch('setRouteInfo', ['ProjectIndex', 'ProjectDetail'])
    }
    // console.log('@store', this.$store.getters.vulnRouteInfo)
    next()
  }
  formatTimestamp(time: number) {
    return formatTimestamp(time)
  }
  private versionList: any[] = []
  private projectObj: ProjectObj = {
    id: 0,
    mode: '',
    name: '',
    owner: '',
    latest_time: '',
    versionData: {},
    agent_languag: [],
    project_version_latest_time: ''
  }

  goProjectList() {
    this.$router.push({
      name: 'pList',
    })
  }

  @Watch('$route.query.pid', { immediate: true })
  async init() {
    if (!this.$route.query.pid) {
      return
    }
    await this.projectsSummary()
    await this.getVersionList()
  }

  private async projectsSummary(id?: string) {
    const { status, msg, data } = await this.services.project.projectsSummary(
      this.$route.query.pid as string,
      id
    )
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }

    this.projectObj = {
      ...data,
      name: data.name,
      latest_time: formatTimestamp(data.latest_time),
    }
  }

  getVersionList() {
    this.services.project
      .versionList(this.$route.query.pid)
      .then((res: any) => {
        if (res.status === 201) {
          this.versionList = res.data
        } else {
          this.$message({
            type: 'error',
            message: res.msg,
            showClose: true,
          })
        }
      })
  }

  private getTagColoe(language: string) {
    switch (language) {
      case 'JAVA':
        return 'danger'
      case 'PYTHON':
        return ''
      default:
        return ''
    }
  }
}
